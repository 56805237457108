import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1141.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M5450 9593 c-324 -31 -536 -77 -820 -177 -172 -61 -245 -92 -406
-176 -474 -245 -902 -634 -1197 -1085 -225 -346 -357 -662 -457 -1095 -54
-237 -81 -630 -61 -875 31 -366 76 -565 228 -990 39 -109 200 -413 295 -555
137 -206 243 -337 407 -501 206 -206 343 -315 586 -467 406 -254 890 -417
1370 -462 141 -13 479 -13 617 0 235 22 503 81 743 164 845 290 1539 942 1897
1781 102 239 183 542 225 840 28 201 25 627 -5 833 -78 522 -234 934 -516
1357 -178 268 -403 512 -672 728 -182 145 -443 306 -644 396 -41 18 -93 41
-115 51 -67 30 -294 106 -395 133 -280 75 -544 108 -849 105 -108 0 -212 -3
-231 -5z m599 -219 c473 -61 918 -228 1299 -486 59 -40 109 -75 112 -78 3 -3
25 -21 50 -40 25 -19 47 -37 50 -40 3 -3 48 -42 100 -87 411 -355 747 -897
888 -1433 185 -702 120 -1413 -187 -2055 -175 -367 -446 -722 -736 -963 -44
-37 -89 -75 -100 -85 -11 -10 -60 -47 -110 -82 -430 -300 -857 -465 -1395
-536 -126 -17 -525 -17 -665 0 -309 37 -667 140 -934 269 -715 344 -1249 932
-1516 1667 -79 218 -132 447 -161 695 -29 254 -11 641 41 896 103 500 325 955
663 1354 62 73 234 245 301 300 29 25 59 50 65 55 37 35 196 149 295 212 113
73 392 216 481 248 367 132 622 188 950 209 101 7 391 -5 509 -20z"/>
<path d="M3870 7125 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M3730 7054 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M4330 7010 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4231 6954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3300 6931 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3390 6931 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4405 6850 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3670 6804 c-140 -36 -283 -142 -351 -262 l-30 -51 90 -100 c81 -89
282 -282 299 -288 10 -4 153 273 145 281 -3 3 -57 6 -120 6 -77 0 -113 4 -113
11 0 6 47 103 104 215 l105 204 -37 -1 c-20 0 -62 -7 -92 -15z"/>
<path d="M4032 6642 l-72 -107 106 -5 c59 -3 109 -9 110 -15 2 -5 -95 -111
-216 -235 l-219 -225 32 -28 c137 -120 437 -273 437 -224 0 7 7 22 15 32 39
51 117 185 136 235 66 174 30 387 -89 536 -33 42 -150 145 -164 144 -2 0 -36
-49 -76 -108z"/>
<path d="M7373 6725 c-47 -22 -52 -30 -25 -44 15 -8 24 -3 46 25 15 20 26 36
24 37 -1 1 -22 -7 -45 -18z"/>
<path d="M5257 6709 c12 -13 26 -18 33 -14 9 6 6 12 -11 21 -36 20 -46 17 -22
-7z"/>
<path d="M3170 6507 c0 -5 16 -35 35 -66 35 -57 35 -58 38 -186 3 -158 17
-197 133 -373 95 -145 155 -260 180 -346 l19 -66 240 2 239 3 26 75 c24 72 26
75 55 73 17 -1 63 -3 103 -3 119 -2 191 46 208 138 7 37 -32 129 -64 148 -17
11 -18 9 -14 -40 14 -165 -152 -195 -388 -70 -186 98 -509 372 -700 594 -95
110 -109 125 -110 117z"/>
<path d="M2945 6400 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M8243 6343 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M8315 6330 c4 -6 12 -10 20 -10 7 0 27 -13 45 -30 41 -39 52 -39 15
0 -26 27 -93 61 -80 40z"/>
<path d="M8246 6279 c3 -5 14 -9 25 -9 11 0 29 -5 40 -10 13 -7 19 -7 19 0 0
5 -3 10 -7 10 -5 0 -25 4 -45 9 -22 5 -36 5 -32 0z"/>
<path d="M8355 6226 c10 -14 19 -26 22 -26 7 0 -17 39 -28 46 -6 3 -3 -5 6
-20z"/>
<path d="M8255 6210 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
<path d="M8446 6195 c8 -37 14 -45 14 -19 0 10 -5 26 -10 34 -8 11 -9 7 -4
-15z"/>
<path d="M8310 6185 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M5899 6163 c-1 -5 0 -86 1 -180 1 -109 -2 -173 -8 -173 -11 0 -50 79
-99 205 -20 49 -40 96 -46 103 -5 6 -26 12 -47 12 -30 0 -39 -4 -44 -22 -4
-13 -10 -129 -13 -259 l-6 -236 37 -5 c20 -2 39 -2 41 1 3 2 5 83 5 179 0 145
2 173 14 163 7 -6 46 -81 87 -166 70 -147 76 -155 107 -161 18 -4 36 -4 39 0
8 8 6 522 -2 536 -7 12 -64 14 -66 3z"/>
<path d="M6505 6158 c-44 -5 -83 -12 -87 -16 -4 -4 -4 -21 1 -37 7 -28 11 -30
52 -30 l44 0 5 -224 5 -225 28 -7 c57 -15 56 -17 59 234 l3 232 43 9 c40 9 43
11 40 40 -3 30 -4 31 -58 32 -30 1 -91 -2 -135 -8z"/>
<path d="M7775 6159 c-38 -5 -73 -13 -77 -17 -5 -5 -5 -23 -2 -41 7 -32 8 -32
53 -29 l46 3 2 -224 c2 -123 4 -225 5 -227 2 -1 21 -4 43 -5 l40 -4 -4 126
c-2 70 0 176 4 237 l7 111 44 7 c45 6 48 11 38 52 -6 20 -12 22 -68 21 -33 -1
-92 -6 -131 -10z"/>
<path d="M6999 6149 l-37 -11 -4 -251 c-2 -139 -1 -256 3 -260 4 -5 38 -9 77
-10 62 -1 75 2 112 28 140 97 161 384 35 481 -42 31 -121 41 -186 23z m128
-84 c94 -66 50 -365 -53 -365 l-24 0 0 183 c0 101 3 187 7 190 11 12 48 8 70
-8z"/>
<path d="M4817 6143 c-2 -4 -8 -24 -12 -43 -4 -19 -31 -134 -61 -255 -30 -120
-54 -227 -54 -237 0 -15 8 -18 44 -18 48 0 45 -5 62 92 8 50 23 60 86 56 l53
-3 10 -50 c11 -48 12 -50 54 -58 23 -5 45 -6 48 -3 7 6 -86 489 -97 506 -9 14
-125 26 -133 13z m83 -170 c6 -38 14 -89 17 -114 l5 -46 -38 -6 c-21 -4 -43
-2 -48 3 -8 8 16 175 31 218 11 27 22 9 33 -55z"/>
<path d="M5416 6138 c-2 -7 -16 -65 -30 -129 -27 -117 -49 -170 -60 -139 -2 8
-14 64 -26 125 -11 60 -26 115 -32 122 -6 8 -33 13 -69 13 -55 0 -59 -1 -66
-27 -4 -16 -7 -128 -7 -251 0 -246 -1 -242 63 -242 l33 0 -6 83 c-11 154 -11
330 -1 334 6 2 20 -45 32 -104 12 -60 30 -141 39 -180 l16 -73 34 0 c19 0 34
3 35 8 3 44 88 337 98 340 12 4 12 -76 -3 -378 -1 -21 4 -25 39 -31 26 -4 43
-3 48 5 4 6 6 125 5 264 -3 212 -5 254 -18 262 -23 15 -120 12 -124 -2z"/>
<path d="M6165 6138 c-15 -42 -132 -534 -128 -540 2 -5 24 -8 48 -8 l43 0 13
70 c14 79 20 84 100 78 l44 -3 9 -52 8 -51 45 -7 c25 -3 47 -5 49 -2 3 3 -61
350 -91 493 -4 16 -15 23 -42 28 -59 9 -93 7 -98 -6z m90 -208 c10 -61 15
-113 12 -116 -11 -11 -77 -11 -86 0 -8 9 5 93 34 214 10 42 22 13 40 -98z"/>
<path d="M7436 6129 c-42 -157 -126 -515 -122 -525 3 -9 21 -14 45 -14 l40 0
11 58 c17 86 19 87 86 87 l59 0 10 -50 c11 -48 12 -50 54 -58 23 -5 45 -6 48
-3 7 6 -87 498 -96 508 -4 5 -34 11 -67 14 -58 6 -61 5 -68 -17z m77 -111 c10
-40 30 -198 26 -202 -2 -2 -22 -6 -44 -8 l-40 -3 1 40 c1 52 33 195 44 195 4
0 10 -10 13 -22z"/>
<path d="M8105 6129 c-3 -13 -32 -133 -65 -266 -33 -133 -60 -249 -60 -257 0
-12 11 -16 44 -16 l45 0 11 58 c6 31 14 64 19 72 9 17 108 27 123 12 4 -4 12
-28 16 -52 7 -43 9 -45 51 -53 23 -5 45 -6 48 -3 3 3 1 31 -5 63 -54 285 -85
439 -91 445 -3 4 -34 10 -68 14 -60 6 -62 6 -68 -17z m78 -111 c13 -53 30
-198 24 -204 -3 -4 -23 -7 -44 -8 -38 -1 -38 -1 -37 39 1 52 33 195 44 195 4
0 10 -10 13 -22z"/>
<path d="M3000 5900 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M3125 5570 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3592 5388 c-17 -17 -15 -45 4 -52 9 -3 107 -6 219 -6 112 0 210 3
219 6 19 7 21 35 4 52 -9 9 -72 12 -223 12 -151 0 -214 -3 -223 -12z"/>
<path d="M3614 5245 c-4 -8 -4 -22 0 -30 5 -13 34 -15 203 -13 l198 3 0 25 0
25 -198 3 c-169 2 -198 0 -203 -13z"/>
<path d="M3674 5115 c-4 -10 9 -29 37 -55 41 -36 48 -39 105 -40 54 0 63 3 89
30 16 17 35 30 43 30 8 0 12 8 10 23 -3 21 -6 22 -141 25 -116 2 -138 0 -143
-13z"/>
</g>
</svg>




		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
